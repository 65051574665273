import React, {useState, useEffect} from 'react'
import './ContactWidget.css'
import validator from "email-validator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEnvelopeOpen, faMinus } from '@fortawesome/free-solid-svg-icons';

function ContactWidget() {

    const [showWidget, setWidget] = useState(true)
    const [formState, setFormState] = useState({
        failedName: false,
        failedEmail: false,
        failedPhone: false,
        failedComment: false
    })

    function contactSubmit(e){
        e.preventDefault();
        let complete = true;
        let tempState = {
            failedName: false,
            failedEmail: false,
            failedPhone: false,
            failedComment: false
        }
        if(e.target[0].value === ""){
            tempState.failedName = true;
            complete = false;
        }
        if(e.target[1].value === ""){
            tempState.failedPhone = true;
            complete = false;
        }
        if(e.target[2].value === ""){
            tempState.failedEmail = true;
            complete = false;
        }
        if(e.target[3].value === ""){
            tempState.failedComment = true;
            complete = false;
        }
        setFormState(tempState);     
        if(complete){
            if(!validator.validate(e.target[2].value)){
                alert("Please enter a valid email address");
                return;
            }
            let phone = e.target[1].value.replace(/\D/g, '');
            if(phone.length !== 10){
                alert("Please enter a valid phone number (1234567890)")
                return;
            }
            try{
            fetch('/api/email', ({
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({name: e.target[0].value, phone: e.target[1].value, email: e.target[2].value, comment: e.target[3].value, subject: "Service inquiry"})
            })).then(response => response.json())
            }
            catch(err){
                console.log(err);
            }
        alert("Message sent!");
        e.target.reset();
        }
        }
    
    return (
        <>
        <div className='cornerButton' style={showWidget ? {"display": "none"} : {}}>
            <FontAwesomeIcon icon={faEnvelope} onClick={() => setWidget(true)}/>
        </div>
        <div className='contactWidget'>
            <div className={'innerWidget ' + (showWidget ? 'moveInner' : '')}>
                <div className='formOpenButton'>
                    <FontAwesomeIcon style={{"visibility": "hidden"}} icon={showWidget ? faEnvelopeOpen : faEnvelope} onClick={() => setWidget(!showWidget)}/>
                    <FontAwesomeIcon icon={showWidget ? faEnvelopeOpen : faEnvelope} onClick={() => setWidget(!showWidget)}/>
                    <FontAwesomeIcon style={showWidget ? {} : {"visibility": "hidden"}} icon={faMinus} onClick={() => setWidget(!showWidget)}/>
                </div>
                <form onSubmit={contactSubmit}>
                    <h1>CONTACT US</h1>
                    <div>
                        <input type='text' name='name' placeholder='Name'/>
                        <span className={formState.failedName ? `invalid` : `valid`}>Please enter your name</span>
                    </div>
                    <div>
                        <input type="text" name='phone' placeholder='Phone'/>
                        <span className={formState.failedPhone ? `invalid` : `valid`}>Please enter your phone</span>
                    </div>
                    <div>
                        <input type="text" name='email' placeholder='Email'/>
                        <span className={formState.failedEmail ? `invalid` : `valid`}>Please enter your email</span>
                    </div>
                    <div>
                        <textarea type="text" name='message' placeholder="Message"/>
                        <span className={formState.failedComment ? `invalid` : `valid`}>Please enter a message</span>
                    </div>
                    <div>
                        <button type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}

export default ContactWidget;