import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark, faPhone, faMailBulk, faV } from '@fortawesome/free-solid-svg-icons';
import house from '../../images/residential.jpg';
import commercial from '../../images/commercial.jpg';
import rodent from '../../images/rodentRemoval.jpg';
import lawn from '../../images/lawnService.jpg';
import './Services.css';

function Services() {

    return (
        <>
            <div className="homeSection">
                <div className='infoHalf' style={{"padding": "0px"}}>
                    <div className='homeText'>
                        <h1>Offered Services</h1>
                        <div className='handleFlex'>
                            <div className='serviceSection' style={{"justifyContent": "center"}}>
                                <div>
                                    <Link style={{"textDecoration": "none"}} to={"/services/residential"}>
                                        <div className='serviceImg' id='residentialLink'>
                                            <img className='servicePic' src={house} alt='pestPic'></img>
                                            <h1 className='centered'>Residential</h1>
                                        </div>
                                    </Link> 
                                </div>
                                <div>
                                    <Link style={{"textDecoration": "none"}} to={"/services/commercial"}>
                                        <div className='serviceImg' id='residentialLink'>
                                            <img className='servicePic' src={commercial} alt='pestPic'></img>
                                            <h1 className='centered'>Commercial</h1>
                                        </div>
                                    </Link> 
                                </div>
                            </div>
                            <div className='serviceSection' style={{"justifyContent": "center"}}>
                                <div>
                                    <Link style={{"textDecoration": "none"}} to={"/services/rodents"}>
                                        <div className='serviceImg' id='residentialLink'>
                                            <img className='servicePic' src={rodent} alt='pestPic'></img>
                                            <h1 className='centered'>Rodent Control</h1>
                                        </div>
                                    </Link> 
                                </div>
                                <div>
                                    <Link style={{"textDecoration": "none"}} to={"/services/lawn"}>
                                        <div className='serviceImg' id='residentialLink'>
                                            <img className='servicePic' src={lawn} alt='pestPic'></img>
                                            <h1 className='centered'>Lawn</h1>
                                        </div>
                                    </Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services;