import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

import logo from '../images/Logo.png'
import './Footer.css';

function Footer() {
    return (
        <div className='footer'>
            <div className='socialIcons'>
                <a href = "tel: 239-333-4100" style={{"marginRight": "auto"}}>(239) 333-4100</a>
                <a href='https://www.facebook.com/accuratepestsolutions/' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon></a>
                <a href="mailto: joe@accupest.pro" style={{"marginLeft": "auto"}}>joe@accupest.pro</a>
            </div>
            <div className='largeRow'>
                <div className='availabilitySection' id='realAvailability'>
                    <div className='availabilityDetail'>
                        <div>Monday-Friday</div>
                        <div className='spacer'/>
                        <div>8AM - 5PM</div>
                    </div>
                    <div className='availabilityDetail'>
                        <div>Saturday</div>
                        <div className='spacer'/>
                        <div>9AM - 12PM</div>
                    </div>
                    <div className='availabilityDetail'>
                        <div>Sunday</div>
                        <div className='spacer'/>
                        <div>By Appointment</div>
                    </div>
                </div>
                <div className='footerLinks'>
                    <Link to="/">Home</Link>
                    <vl></vl>
                    <Link to="/services">Services</Link>
                    <vl></vl>
                    {/* <Link to="/faq">FAQ</Link>
                    <vl></vl> */}
                    <Link to="/testimonials">Testimonials</Link>
                    <vl></vl>
                    <a href='https://accuratepestsolutions.fieldportals.com/landing/index' rel="noreferrer" target='_blank'>Pay Now</a>
                </div>
                <div style={{"visibility": "hidden"}}  className='availabilitySection'>
                    <div className='availabilityDetail'>
                        <div>Monday-Friday</div>
                        <div className='spacer'/>
                        <div>8AM - 5PM</div>
                    </div>
                    <div className='availabilityDetail'>
                        <div>Saturday</div>
                        <div className='spacer'/>
                        <div>9AM - 12PM</div>
                    </div>
                    <div className='availabilityDetail'>
                        <div>Sunday</div>
                        <div className='spacer'/>
                        <div>By Appointment</div>
                    </div>
                </div>
            </div>
            <a href='https://www.google.com/maps/place/8359+Beacon+Blvd,+Fort+Myers,+FL+33907/@26.5709895,-81.8719749,17z/data=!3m1!4b1!4m6!3m5!1s0x88db4003d0546b83:0xfa169dc3d2a490d!8m2!3d26.5709847!4d-81.8694!16s%2Fg%2F11cpmmlnx6?entry=ttu' rel="noreferrer" target='_blank'>
                <FontAwesomeIcon icon={faMapLocationDot} style={{"fontSize": "36px"}}></FontAwesomeIcon>
            </a>
        </div>
    )
}

export default Footer;