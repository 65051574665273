import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import mouseTrap from '../../images/mouse-trap.jpg'
import commercial from '../../images/restaurant.jpg'
import residential from '../../images/house.jpg'
import lawn from '../../images/lawn.jpg'
import antWalking from '../../images/antWalking.mp4'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';


function Home() {
    return (
        <div>
            <div className="homeSection">
                <div className='infoHalf'>
                    <div className='homeText'>
                        <h1>"Quality Service at its Best"</h1>
                        {/* Why we're great */}
                        <div className='descHero'>
                            <video autoPlay muted loop>
                                <source src={antWalking} type="video/mp4"/>
                            </video>
                            <div>
                                <div>
                                    <Link to={"/contact"}>
                                        <button className='videoButton'>Get A Quote</button>
                                    </Link>
                                </div>
                                <vl></vl>
                                <div>
                                    <h3 style={{"margin": "0"}}>Satisfaction Guaranteed</h3>
                                </div>
                                <vl></vl>
                                <div>
                                    <Link to={"/callback"}>
                                        <button className='videoButton'>Request A Callback</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='handleFlex'>
                            <div className='handleSection'>
                                <div>
                                    <img className='rodentPic' src={residential} alt='residentialPic'></img>
                                    <h1>Residential</h1>
                                    <p>Your home is your comfort zone. Don't put up with pests in your home! We provide numerous 
                                        services to protect your home, removing them from your space and preventing them from returning.
                                    </p>
                                    <Link to="/services/residential">
                                        <button>Learn More</button>
                                    </Link>
                                </div>
                                <div>
                                    <img className='rodentPic' src={commercial} alt='commercialPic'></img>
                                    <h1>Commercial</h1>
                                    <p>Owning a restaurant or business is no small task. You shouldn't have to worry about unwanted guests. 
                                        Let us find pests before your customers do! Keeping your business clean and tidy will help your customers 
                                        stay comfortable and prevent you from losing business. 
                                    </p>
                                    <Link to="/services/commercial">
                                        <button>Learn More</button>
                                    </Link>
                                </div>
                            </div>
                            <div className='handleSection'>
                                <div>
                                    <img className='rodentPic' src={mouseTrap} alt='rodentPic'></img>
                                    <h1>Rodent Control</h1>
                                    <p>We provide quick and reliable rodent control services that are tailored to you and your needs. 
                                        We handle both residential and commercial rodents to give you the peace of mind you deserve.
                                    </p>
                                    <Link to="/services/rodents">
                                        <button>Learn More</button>
                                    </Link>
                                </div>
                                <div>
                                    <img className='rodentPic' src={lawn} alt='lawnPic'></img>
                                    <h1>Other Services</h1>
                                    <p>At Accurate Pest Solutions, we provide several specific services that don't fall under a broad umbrella. 
                                        We believe that every pest concern is a valid one, so we do all we can to ensure that we can meet your needs and expectations,
                                         whatever they might be.
                                    </p>
                                    <Link to="/services/other">
                                        <button>Learn More</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='homeSection' style={{"justifyContent": "space-evenly", "paddingBottom": "10px"}}>
                <iframe title="Accupest Solutions location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7138.484848696674!2d-81.84790517881446!3d26.544478862293463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88db3f8559151caf%3A0x726a1586ae0ed5fc!2sAccurate%20Pest%20Solutions%2C%20LLC!5e0!3m2!1sen!2sus!4v1689102173712!5m2!1sen!2sus" width="600" height="450" style={{"border":"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <div className='locationSection' style={{"textAlign": "center"}}>
                <a href='https://www.google.com/maps/place/8359+Beacon+Blvd,+Fort+Myers,+FL+33907/@26.5709895,-81.8719749,17z/data=!3m1!4b1!4m6!3m5!1s0x88db4003d0546b83:0xfa169dc3d2a490d!8m2!3d26.5709847!4d-81.8694!16s%2Fg%2F11cpmmlnx6?entry=ttu' rel="noreferrer" target='_blank'>
                    <FontAwesomeIcon icon={faMapLocationDot} style={{"fontSize": "36px"}}></FontAwesomeIcon>
                </a>
                    <h2>
                        Located in Fort Myers, Florida
                    </h2>
                    <h3>
                        8359 Beacon Blvd, Unit 220
                    </h3>
                    <p>Proudly serving:</p>
                    <p style={{"margin": "10px"}}>Fort Myers, Cape Coral, Estero, Bonita Springs, Fort Myers Beach, Sanibel, Captiva, Charlotte, and Collier counties</p>
                    <a href='https://www.google.com/maps/place/8359+Beacon+Blvd,+Fort+Myers,+FL+33907/@26.5709895,-81.8719749,17z/data=!3m1!4b1!4m6!3m5!1s0x88db4003d0546b83:0xfa169dc3d2a490d!8m2!3d26.5709847!4d-81.8694!16s%2Fg%2F11cpmmlnx6?entry=ttu' rel="noreferrer" target='_blank'>
                        <button className='directionsButton'>Get Directions</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Home;