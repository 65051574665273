import React, {useState} from 'react'
import kitchen from "../../images/kitchen.jpg"
import './Residential.css';
import validator from "email-validator";


function Residential() {

    const [formState, setFormState] = useState({
        failedName: false,
        failedEmail: false,
        failedPhone: false,
        failedComment: false
    })

    function contactSubmit(e){
        e.preventDefault();
        let complete = true;
        let tempState = {
            failedName: false,
            failedEmail: false,
            failedPhone: false,
            failedComment: false
        }
        if(e.target[0].value === ""){
            tempState.failedName = true;
            complete = false;
        }
        if(e.target[1].value === ""){
            tempState.failedPhone = true;
            complete = false;
        }
        if(e.target[2].value === ""){
            tempState.failedEmail = true;
            complete = false;
        }
        if(e.target[3].value === ""){
            tempState.failedComment = true;
            complete = false;
        }
        setFormState(tempState);     
        if(complete){
            if(!validator.validate(e.target[2].value)){
                alert("Please enter a valid email address");
                return;
            }
            let phone = e.target[1].value.replace(/\D/g, '');
            if(phone.length !== 10){
                alert("Please enter a valid phone number (1234567890)")
                return;
            }
            try{
            fetch('/api/email', ({
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({name: e.target[0].value, phone: e.target[1].value, email: e.target[2].value, comment: e.target[3].value, subject: "Service inquiry"})
            })).then(response => response.json())
            }
            catch(err){
                console.log(err);
            }
        alert("Message sent!");
        e.target.reset();
        }
        }

    return (
        <>
            <div className="homeSection">
                <div className='infoHalf'>
                    <div className='homeText'>
                        <h1>Residential Services</h1>
                    </div>
                </div>
            </div>
            <div className='serviceInfo'>
                <div className='serviceText'>
                    <h1 style={{"marginBottom": "none"}}>Bugs and rodents don't belong in your home.  
                        Whether they're digging around in your walls or building their family 
                        residence in your yard,</h1>
                    <h1 style={{"fontSize": "4em", "borderBottom": "5px solid #fddc11", "fontWeight": "bolder", "fontStyle": "italic"}}>We're Here to Help.</h1>
                    <div style={{"display": "inline-block", "textAlign": "left"}}>
                        <p>We proudly serve the greater Fort Myers Area with any 
                            pest problem you experience in your home or your yard. There's no question 
                            that pests are annoying, but the damage they cause is ugly and can get 
                            very expensive. Let us help you get rid of those annoying pests before they 
                            force you to drain your savings to create a livable environment for your family.
                             No matter how weird your problem is (seriously, the bugs and rodents we see in 
                             Florida are ridiculous), we've dealt with it and will be thrilled to 
                             get your house back to normal. </p>
                    </div>
                    {/* 
                     */}
                </div>
                <div className='serviceRight'>
                    <img src={kitchen} alt='kitchen'></img>
                </div>
            </div>
            <div className="serviceContact">
                {/* <div className='serviceContactInfo'>
                    <h2><FontAwesomeIcon icon={faPhone}/> (239) 333-4100</h2>
                    <h2><FontAwesomeIcon icon={faMailBulk}/> joe@accupest.pro</h2>
                    <h2>Fort Myers, Florida</h2>
                    <h3>11547 Charlies Terrace</h3>
                </div> */}
                <div className='serviceForm'>
                <form onSubmit={contactSubmit}>
                    <h1>Let Us Protect Your liveliehood!</h1>           
                    <div>
                        <label>Name</label>
                        <input type='text' name='name' placeholder='John Doe'/>
                        <span className={formState.failedName ? `invalid` : `valid`}>Please enter your name</span>
                    </div>
                    <div>
                        <label>Phone</label>
                        <input type="text" name='phone' placeholder='000-000-0000'/>
                        <span className={formState.failedPhone ? `invalid` : `valid`}>Please enter your phone</span>
                    </div>
                    <div>
                        <label>Email</label>
                        <input type="text" name='email' placeholder='JohnDoe@gmail.com'/>
                        <span className={formState.failedEmail ? `invalid` : `valid`}>Please enter your email</span>
                    </div>
                    <div>
                        <label>Message</label>
                        <textarea type="text" name='message' placeholder="Tell Us About Your Residential Worries..."/>
                        <span className={formState.failedComment ? `invalid` : `valid`}>Please enter a message</span>
                    </div>
                    <div>
                        <button type='submit'>Submit</button>
                    </div>
                </form>
                </div>
            </div>
        </>
    )
}

export default Residential;