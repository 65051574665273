import React, {useState} from 'react'
import './Contact.css'
import validator from "email-validator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMailBulk, faMapPin } from '@fortawesome/free-solid-svg-icons';


function Contact() {

    const [formState, setFormState] = useState({
        failedName: false,
        failedEmail: false,
        failedPhone: false,
        failedComment: false
    })

    function contactSubmit(e){
        e.preventDefault();
        let complete = true;
        let tempState = {
            failedName: false,
            failedEmail: false,
            failedPhone: false,
            failedComment: false
        }
        if(e.target[0].value === ""){
            tempState.failedName = true;
            complete = false;
        }
        if(e.target[1].value === ""){
            tempState.failedPhone = true;
            complete = false;
        }
        if(e.target[2].value === ""){
            tempState.failedEmail = true;
            complete = false;
        }
        if(e.target[3].value === ""){
            tempState.failedComment = true;
            complete = false;
        }
        setFormState(tempState);     
        if(complete){
            if(!validator.validate(e.target[2].value)){
                alert("Please enter a valid email address");
                return;
            }
            let phone = e.target[1].value.replace(/\D/g, '');
            if(phone.length !== 10){
                alert("Please enter a valid phone number (1234567890)")
                return;
            }
            try{
            fetch('/api/email', ({
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({name: e.target[0].value, phone: e.target[1].value, email: e.target[2].value, comment: e.target[3].value, subject: "Service inquiry"})
            })).then(response => response.json())
            }
            catch(err){
                console.log(err);
            }
        alert("Message sent!");
        e.target.reset();
        }
        }
   
    return (
        <>
        <div className="homeSection">
            <div className='infoHalf'>
                <div className='homeText'>
                    <h1>Contact Us</h1>
                </div>
            </div>
        </div>
        <div className="contactContainer">
                <form className='contactForm' onSubmit={contactSubmit}>        
                    <div>
                        <label>Name</label>
                        <input type='text' name='name' placeholder='John Doe'/>
                        <span className={formState.failedName ? `invalid` : `valid`}>Please enter your name</span>
                    </div>
                    <div>
                        <label>Phone</label>
                        <input type="text" name='phone' placeholder='000-000-0000'/>
                        <span className={formState.failedPhone ? `invalid` : `valid`}>Please enter your phone</span>
                    </div>
                    <div>
                        <label>Email</label>
                        <input type="text" name='email' placeholder='JohnDoe@gmail.com'/>
                        <span className={formState.failedEmail ? `invalid` : `valid`}>Please enter your email</span>
                    </div>
                    <div>
                        <label>Message</label>
                        <textarea type="text" name='message' placeholder="Tell Us How We Can Help"/>
                        <span className={formState.failedComment ? `invalid` : `valid`}>Please enter a message</span>
                    </div>
                    <div>
                        <button type='submit'>Submit</button>
                    </div>
                </form>
            <div className='contactContainerInfo'>
                <h1>Accurate Pest Solutions, LLC</h1>
                <div className='contactDetail'>
                    <a href="tel: 239-333-4100" rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faPhone}/></a>
                    <div className='contactSpacer'/>
                    <h2>(239) 333-4100</h2>
                </div>
                <div className='contactDetail'>
                    <a href="mailto: joe@accupest.pro" rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faMailBulk}/></a>
                    <div className='contactSpacer'/>
                    <h2>joe@accupest.pro</h2> 
                </div>
                <div className='contactDetail'>
                    <a href="https://www.google.com/maps/place/8359+Beacon+Blvd,+Fort+Myers,+FL+33907/@26.5709895,-81.8719749,17z/data=!3m1!4b1!4m6!3m5!1s0x88db4003d0546b83:0xfa169dc3d2a490d!8m2!3d26.5709847!4d-81.8694!16s%2Fg%2F11cpmmlnx6?entry=ttu" rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faMapPin}/></a>
                    <div className='contactSpacer'/>
                    <div style={{"width": "fitContent"}}>
                        <h2>Fort Myers, FL</h2>
                        <h2>8359 Beacon Blvd, Unit 220</h2>
                    </div>
                </div>
                {/* <h1>Availability:</h1>
                <div className='contactDetail'>
                    <h2>Monday-Friday</h2>
                    <div className='contactSpacer'></div>
                    <h2>8AM - 5PM</h2>
                </div>
                <div className='contactDetail'>
                    <h2>Saturday</h2>
                    <div className='contactSpacer'></div>
                    <h2>9AM - 12PM</h2>
                </div>
                <div className='contactDetail'>
                    <h2>Sunday</h2>
                    <div className='contactSpacer'></div>
                    <h2>By Appointment</h2>
                </div> */}
            </div>
        </div>
        </>
    )
}

export default Contact;