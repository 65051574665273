import logo from './logo.svg';
import "./index.css"
import React, {useEffect} from 'react';
import ReactDOM from "react-dom/client";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Pages/Home";
// import FAQ from "./components/Pages/Faq";
import ContactWidget from "./components/ContactWidget";
import Services from './components/Pages/Services';
import Residential from './components/Pages/Residential';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Commercial from './components/Pages/Commercial';
import Rodent from './components/Pages/Rodent';
import Lawn from './components/Pages/Other';
import Testimonials from './components/Pages/Testimonials';
import Contact from './components/Pages/Contact';
import Callback from './components/Pages/Callback';
import ScrollToTop from './components/ScrollToTop';
import Privacypolicy from './components/Pages/Privacypolicy';
import SousaFooter from './components/SousaFooter';

function App() {
  
  return (
    <React.StrictMode>
      <div className="pageContainer">
        <div className="contentWrap">
          <Router>
            <ScrollToTop/>
            <Navbar/>
            <Routes>
              <Route path='/' element={<Home/>}/>
              {/* <Route path='/FAQ' element={<FAQ/>}/> */}
              <Route path='/services' element={<Services/>}/>
              <Route path='/services/residential' element={<Residential/>}/>
              <Route path='/services/commercial' element={<Commercial/>}/>
              <Route path='/services/rodents' element={<Rodent/>}/>
              <Route path='/services/other' element={<Lawn/>}/>
              <Route path='/testimonials' element={<Testimonials/>}/>
              <Route path='/contact' element={<Contact/>}/>
              <Route path='/callback' element={<Callback/>}/>
              <Route path='/privacy-policy' element={<Privacypolicy/>}/>
            </Routes>
            <ContactWidget/>
            <Footer/>
            <SousaFooter/>
          </Router>
        </div>
      </div>
      </React.StrictMode>
  );
}

export default App;
