import React, {useState} from 'react'
import './Testimonials.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";


function Testimonials() {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 1920 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 1920, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 650 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 650, min: 0 },
          items: 1
        }
      };
    return (
        <>
        <div className="homeSection">
            <div className='infoHalf'>
                <div className='homeText'>
                    <h1>Testimonials</h1>
                </div>
            </div>
        </div>
        <div className='testimonialIntroduction'>
            <p>
                We believe in a job done right. Accurate Pest Solutions is dedicated to providing outstanding service at
                reasonable prices. Accurate Pest Solutions is a family owned company operating out of Fort Myers, Florida, 
                and we know how infuriating pests can get here. We understand that when you call us, you want your pests 
                handled by an expert. We pride ourselves on delivering 
            </p>
            <h1>Quality Service At Its Best</h1>
            <p>Don't take our word for it. Here are just a few reviews from our valued customers:</p>
        </div>
        <div className='testimonialSection'>
            <Carousel responsive={responsive} showDots={true} draggable={false}>
                <div className='testimonial'>
                    <p>"Joe with Accurate Pest Control has done a great job on my home. He is on time, thorough with his 
                        inspection and treatments, and very polite. I will recommend Accurate to all my friends and family."</p>
                    <h2>-Ruben L.</h2>    
                </div>
                <div className='testimonial'>
                    <p>"Excellent services and very friendly and knowledgeable about their work. Thank you !"</p>
                    <h2>-Wendy R.</h2>    
                </div>
                <div className='testimonial'>
                    <p>"Very pleased with Accurate pest solutions.  They go above and beyond their norm. Everyone is extremely friendly and responsive.  
                        Thank you for taking care of our lawn and pest control!!"</p>
                    <h2>-Jami S.</h2>    
                </div>
                <div className='testimonial'>
                    <p>"Professional, knowledgeable and personable. Joe does a great job leading his business. Has excellent communication and treatment solutions. We have kids in our home..and he uses safe and extremely effective methods. I appreciate his knowledge and the passion he has for what he does. 
                        He has treated both my home and business. Im always impressed. The best around, in opinion.."</p>
                    <h2>-Billy C.</h2>    
                </div>
                <div className='testimonial'>
                    <p>"Great service... very knowledgeable staff. Always on time when appointment is scheduled. Work is always preformed thoroughly. Staff are are neat and careful inside my home. Recently had the pleasure of using their lawn decision services. 
                        It is worth the money and certainly adds to curb appeal."</p>
                    <h2>-Andrew B.</h2>    
                </div>
            </Carousel>
        </div>
        <div className='testimonialIntroduction'>
            <h2>You can find even more stellar reviews on our Google page:</h2>
            <a href='https://www.google.com/maps/place/Accurate+Pest+Solutions,+LLC/@26.5721455,-81.8715814,17z/data=!4m8!3m7!1s0x88db3f8559151caf:0x726a1586ae0ed5fc!8m2!3d26.5721455!4d-81.8690011!9m1!1b1!16s%2Fg%2F11c38j0mfm' rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faGoogle}/></a>
        </div>
        </>
    )
}

export default Testimonials;