import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

function ScrollToTop() {
    const {pathname} = useLocation();
  useEffect(() => {
    console.log("hello")
      window.scrollTo(0, 0);
      document.body.scrollTo(0,0)
  }, [pathname]);

  return (null);
}

export default ScrollToTop;