import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark, faPhone, faMailBulk, faV } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/Logo.png'
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [logoHeight, setLogo] = useState({"maxWidth": "300px"})
    const [shrink, setShrink] = useState(false)
    const [isMobile, setMobile] = useState(false)

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    useEffect(() => {
        console.log("useEffect");
        window.addEventListener("scroll", resizeBar);
        setMobile(window.innerWidth <= 1020);
    }, [])

    function resizeBar(){
        const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 120;
        if(distanceY > shrinkOn){
            setShrink(true)
            setLogo({"maxWidth": "250px"})
        }
        else{
            setShrink(false)
            setLogo({"maxWidth": "300px"})
        }

    }
    console.log(isMobile);

    return (
        <>
            <nav id="nav" className={"navbar " + (shrink ? "navShrink" : "")}>
                <div className={'contactBar ' + (shrink ? "contactBarShrink" : "")}>
                    <div>
                        <FontAwesomeIcon icon={faPhone}/>
                        (239) 333-4100
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faMailBulk}/>
                        joe@accupest.pro
                    </div>
                </div>
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img alt="logo" src={logo} style={logoHeight}/>
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i>
                           <FontAwesomeIcon icon={click ? faXmark : faBars}/> 
                        </i>
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className={'nav-links ' + (shrink ? "gradientEffectShrink" : "gradientEffect")} onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item' id='serviceButton'>
                            <Link onClick={closeMobileMenu} to='/services' className={'nav-links ' + (shrink ? "gradientEffectShrink" : "gradientEffect")}>
                                Services<FontAwesomeIcon icon={!isMobile ? faV : null}/>
                            </Link>
                            <div className='serviceList'>
                                <Link to='/services/residential' className={'nav-links ' + (shrink ? "gradientEffectShrink" : "gradientEffect")} onClick={closeMobileMenu}>
                                    Residential
                                </Link>
                                <Link to='/services/commercial' className={'nav-links ' + (shrink ? "gradientEffectShrink" : "gradientEffect")} onClick={closeMobileMenu}>
                                    Commercial
                                </Link>
                                <Link to='/services/rodents' className={'nav-links ' + (shrink ? "gradientEffectShrink" : "gradientEffect")} onClick={closeMobileMenu}>
                                    Rodent Control
                                </Link>
                                <Link to='/services/other' className={'nav-links ' + (shrink ? "gradientEffectShrink" : "gradientEffect")} onClick={closeMobileMenu}>
                                    Other Services
                                </Link>
                            </div>
                        </li>
                        {/* <li className='nav-item'>
                            <Link to='/faq' className={'nav-links ' + (shrink ? "gradientEffectShrink" : "gradientEffect")} onClick={closeMobileMenu}>
                                FAQ
                            </Link>
                        </li> */}
                        <li className='nav-item'>
                            <Link to='/testimonials' className={'nav-links ' + (shrink ? "gradientEffectShrink" : "gradientEffect")} onClick={closeMobileMenu}>
                                Testimonials
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <a href='https://accuratepestsolutions.fieldportals.com/landing/index' rel="noreferrer" target='_blank' className={'nav-links ' + (shrink ? "gradientEffectShrink" : "gradientEffect")} onClick={closeMobileMenu}>
                                Pay Now
                            </a>
                        </li>
                    </ul>
                    <div id='navButton' style={shrink ? {"width": "250px"} : {}}>
                        <Link to='/contact'>
                            <button style={shrink ? {"backgroundColor": "#fddc11", "color": "#232323"} : {"backgroundColor": "#232323", "color": "#fddc11"}}>
                                Get A Quote
                            </button>
                        </Link>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;